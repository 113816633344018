import React, { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faRupeeSign,
    faChevronRight,
    faCalendar,
    faVacuum,
    faHouse,
    faSitemap,
    faCloudUploadAlt,
    faPoundSign,
    faSearch,
    faPiggyBank,
    faIdCard,
    faScanner,
    faSirenOn,
    faBullhorn,
    faPeopleArrows,
    faQuestion,
    faQuestionCircle,
    faRepeat,
} from "@fortawesome/pro-regular-svg-icons";
import { services,BULK_FLOW } from "../../services/MatrixService";
import { service } from "../../services/EntertainmentResidencyService";
import { venueservice } from "../../services/VenueHierarachyService";
import { feeService } from "../../services/ServiceFeesService";
import { providerservice } from "../../services/VenueProviderService";
import UserService, { RoleGroupNames } from "../../services/UserService";
import DatePanel from "./DatePanel";

interface ManageOurServicesPanelProps extends WithTranslation {
    setFilter: (status: string) => void;
}

interface ManageOurServicesPanelState {}

class ManageOurServicesPanel extends Component<ManageOurServicesPanelProps> {
    state: ManageOurServicesPanelState;

    constructor(props: ManageOurServicesPanelProps) {
        super(props);
        this.state = {};
    }

    onManageServiceClick = (serviceType: string) => {
        this.props.setFilter(serviceType);
    };

    render() {
        return (
            <>
                <DatePanel />
                <ul className="side-bar-links">
                    <div className="mt-5 pt-3 sidebar__divider">
                        <h4 className="side-bar-links__heading">CONTRACT CLEANING</h4>
                    </div>
                    <li>
                        <Link
                            onClick={() => this.onManageServiceClick("ContractCleaning")}
                            className="side-bar-links__link"
                            to={{
                                pathname: "/matrixView",
                                state: {
                                    service: services.contract,
                                },
                            }}
                        >
                            <span className="side-bar-links__icon">
                                <FontAwesomeIcon icon={faVacuum} />
                            </span>
                            <span>Weekly Cleaning</span>
                            <span className="side-bar-links__number ml-auto mr-5"></span>
                            <span className="side-bar-links__chevron ">
                                <FontAwesomeIcon icon={faChevronRight} />
                            </span>
                        </Link>
                    </li>
                    <div className="mt-5 pt-3 sidebar__divider">
                        <h4 className="side-bar-links__heading">ENTERTAINMENT</h4>
                    </div>
                    <li>
                        <Link
                            onClick={() => this.onManageServiceClick("Entertainment")}
                            className="side-bar-links__link"
                            to={{
                                pathname: "/matrixView",
                                state: {
                                    service: service.entertainment,
                                },
                            }}
                        >
                            <span className="side-bar-links__icon">
                                <FontAwesomeIcon icon={faHouse} />
                            </span>
                            <span>RESIDENCIES</span>
                            <span className="side-bar-links__number ml-auto mr-5"></span>
                            <span className="side-bar-links__chevron ">
                                <FontAwesomeIcon icon={faChevronRight} />
                            </span>
                        </Link>
                    </li>
                    <div className="mt-5 pt-3 sidebar__divider">
                        <h4 className="side-bar-links__heading">DOOR SUPERVISION</h4>
                    </div>
                    <li>
                        <Link
                            onClick={() => this.onManageServiceClick("RateMatrix")}
                            className="side-bar-links__link"
                            to={{
                                pathname: "/matrixView",
                                state: {
                                    service: services.rateMatrix,
                                },
                            }}
                        >
                            <span className="side-bar-links__icon">
                                <FontAwesomeIcon icon={faRupeeSign} />
                            </span>
                            <span>RATE MATRIX</span>
                            <span className="side-bar-links__number ml-auto mr-5"></span>
                            <span className="side-bar-links__chevron ">
                                <FontAwesomeIcon icon={faChevronRight} />
                            </span>
                        </Link>
                    </li>

                    <li>
                        <Link
                            onClick={() => this.onManageServiceClick("RateMatrixUploader")}
                            className="side-bar-links__link"
                            to={{
                                pathname: "/matrixView",
                            }}
                        >
                            <span className="side-bar-links__icon fa-flip-horizontal">
                                <FontAwesomeIcon icon={faPiggyBank} />
                            </span>
                            <span>RATE MATRIX UPLOADER</span>
                            <span className="side-bar-links__number ml-auto mr-5"></span>
                            <span className="side-bar-links__chevron ">
                                <FontAwesomeIcon icon={faChevronRight} />
                            </span>
                        </Link>
                    </li>
                    <li>
                        <Link
                            onClick={() => this.onManageServiceClick("ShiftImportWarnings")}
                            className="side-bar-links__link"
                            to={{
                                pathname: "/matrixView",
                            }}
                        >
                            <span className="side-bar-links__icon fa-flip-horizontal">
                                <FontAwesomeIcon icon={faSirenOn} />
                            </span>
                            <span>SHIFT IMPORT WARNINGS</span>
                            <span className="side-bar-links__number ml-auto mr-5"></span>
                            <span className="side-bar-links__chevron ">
                                <FontAwesomeIcon icon={faChevronRight} />
                            </span>
                        </Link>
                    </li>
                    <li>
                        <Link
                            onClick={() => this.onManageServiceClick("NewLicenseChecks")}
                            className="side-bar-links__link"
                            to={{
                                pathname: "/matrixView",
                            }}
                        >
                            <span className="side-bar-links__icon fa-flip-horizontal">
                                <FontAwesomeIcon icon={faScanner} />
                            </span>
                            <span>SIA License - Request Checks</span>
                            <span className="side-bar-links__number ml-auto mr-5"></span>
                            <span className="side-bar-links__chevron ">
                                <FontAwesomeIcon icon={faChevronRight} />
                            </span>
                        </Link>
                    </li>
                    <li>
                        <Link
                            onClick={() => this.onManageServiceClick("LicenseChecks")}
                            className="side-bar-links__link"
                            to={{
                                pathname: "/matrixView",
                            }}
                        >
                            <span className="side-bar-links__icon fa-flip-horizontal">
                                <FontAwesomeIcon icon={faIdCard} />
                            </span>
                            <span>SIA License - view checks</span>
                            <span className="side-bar-links__number ml-auto mr-5"></span>
                            <span className="side-bar-links__chevron ">
                                <FontAwesomeIcon icon={faChevronRight} />
                            </span>
                        </Link>
                    </li>
                    <div className="mt-5 pt-3 sidebar__divider">
                        <h4 className="side-bar-links__heading">CLIENT</h4>
                    </div>
                    <li>
                        <Link
                            onClick={() => this.onManageServiceClick("Budgets")}
                            className="side-bar-links__link"
                            to={{
                                pathname: "/matrixView",
                            }}
                        >
                            <span className="side-bar-links__icon fa-flip-horizontal">
                                <FontAwesomeIcon icon={faPiggyBank} />
                            </span>
                            <span>BUDGETS</span>
                            <span className="side-bar-links__number ml-auto mr-5"></span>
                            <span className="side-bar-links__chevron ">
                                <FontAwesomeIcon icon={faChevronRight} />
                            </span>
                        </Link>
                    </li>
                    <li>
                        <Link
                            onClick={() => this.onManageServiceClick("BudgetUploader")}
                            className="side-bar-links__link"
                            to={{
                                pathname: "/matrixView",
                            }}
                        >
                            <span className="side-bar-links__icon fa-flip-horizontal">
                                <FontAwesomeIcon icon={faPiggyBank} />
                            </span>
                            <span>BUDGET UPLOADER</span>
                            <span className="side-bar-links__number ml-auto mr-5"></span>
                            <span className="side-bar-links__chevron ">
                                <FontAwesomeIcon icon={faChevronRight} />
                            </span>
                        </Link>
                    </li>
                    <li>
                        <Link
                            onClick={() => this.onManageServiceClick(feeService.ServiceFeeMatrix)}
                            className="side-bar-links__link"
                            to={{
                                pathname: "/matrixView",
                                state: {
                                    service: feeService.ServiceFeeMatrix,
                                },
                            }}
                        >
                            <span className="side-bar-links__icon">
                                <FontAwesomeIcon icon={faPoundSign} />
                            </span>
                            <span>Client Service Fees</span>
                            <span className="side-bar-links__number ml-auto mr-5"></span>
                            <span className="side-bar-links__chevron ">
                                <FontAwesomeIcon icon={faChevronRight} />
                            </span>
                        </Link>
                    </li>
                    <li>
                        <Link
                            onClick={() => this.onManageServiceClick("PeriodMatrix")}
                            className="side-bar-links__link"
                            to={{
                                pathname: "/matrixView",
                                state: {
                                    service: services.periodMatrix,
                                },
                            }}
                        >
                            <span className="side-bar-links__icon">
                                <FontAwesomeIcon icon={faCalendar} />
                            </span>
                            <span>PERIOD MATRIX</span>
                            <span className="side-bar-links__number ml-auto mr-5"></span>
                            <span className="side-bar-links__chevron">
                                <FontAwesomeIcon icon={faChevronRight} />
                            </span>
                        </Link>
                    </li>
                    <li>
                        <Link
                            onClick={() => this.onManageServiceClick("PeriodMatrixUploader")}
                            className="side-bar-links__link"
                            to={{
                                pathname: "/matrixView",
                            }}
                        >
                            <span className="side-bar-links__icon fa-flip-horizontal">
                                <FontAwesomeIcon icon={faCloudUploadAlt} />
                            </span>
                            <span>PERIOD MATRIX UPLOADER</span>
                            <span className="side-bar-links__number ml-auto mr-5"></span>
                            <span className="side-bar-links__chevron ">
                                <FontAwesomeIcon icon={faChevronRight} />
                            </span>
                        </Link>
                    </li>                    
                    <li>
                        <Link
                            onClick={() => this.onManageServiceClick("ViewClients")}
                            className="side-bar-links__link"
                            to={{
                                pathname: "/matrixView",
                                state: {
                                    navigateFrom: "viewClients",
                                },
                            }}
                        >
                            <span className="side-bar-links__icon">
                                <FontAwesomeIcon icon={faSearch} />
                            </span>
                            <span>VIEW CLIENTS</span>
                            <span className="side-bar-links__number ml-auto mr-5"></span>
                            <span className="side-bar-links__chevron">
                                <FontAwesomeIcon icon={faChevronRight} />
                            </span>
                        </Link>
                    </li>
                    <li>
                        <Link
                            onClick={() => this.onManageServiceClick("ViewClientContact")}
                            className="side-bar-links__link"
                            to={{
                                pathname: "/matrixView",
                                state: {
                                    navigateFrom: "viewContacts",
                                },
                            }}
                        >
                            <span className="side-bar-links__icon">
                                <FontAwesomeIcon icon={faSearch} />
                            </span>
                            <span>VIEW CONTACTS</span>
                            <span className="side-bar-links__number ml-auto mr-5"></span>
                            <span className="side-bar-links__chevron">
                                <FontAwesomeIcon icon={faChevronRight} />
                            </span>
                        </Link>
                    </li>
                    <li>
                        <Link
                            onClick={() => this.onManageServiceClick("ViewVenue")}
                            className="side-bar-links__link"
                            to={{
                                pathname: "/matrixView",
                                state: {
                                    navigateFrom: "viewVenue",
                                },
                            }}
                        >
                            <span className="side-bar-links__icon">
                                <FontAwesomeIcon icon={faSearch} />
                            </span>
                            <span>VIEW VENUES</span>
                            <span className="side-bar-links__number ml-auto mr-5"></span>
                            <span className="side-bar-links__chevron">
                                <FontAwesomeIcon icon={faChevronRight} />
                            </span>
                        </Link>
                    </li>
                    <li>
                        <Link
                            onClick={() => this.onManageServiceClick(venueservice.venueHierarchy)}
                            className="side-bar-links__link"
                            to={{
                                pathname: "/matrixView",
                                state: {
                                    service: venueservice.venueHierarchy,
                                },
                            }}
                        >
                            <span className="side-bar-links__icon">
                                <FontAwesomeIcon icon={faSitemap} />
                            </span>
                            <span>Venue Hierarchy</span>
                            <span className="side-bar-links__number ml-auto mr-5"></span>
                            <span className="side-bar-links__chevron ">
                                <FontAwesomeIcon icon={faChevronRight} />
                            </span>
                        </Link>
                    </li>
                    <li>
                        <Link
                            onClick={() => this.onManageServiceClick('venueHierarchyBulkUpdate')}
                            className="side-bar-links__link"
                            to={{
                                pathname: "/matrixView",
                                state: {
                                    navigateFrom: "venueHierarchyBulkUpdate",
                                },
                            }}
                        >
                            <span className="side-bar-links__icon">
                                <FontAwesomeIcon icon={faSitemap} />
                            </span>
                            <span>Venue Hierarchy Bulk Update</span>
                            <span className="side-bar-links__number ml-auto mr-5"></span>
                            <span className="side-bar-links__chevron ">
                                <FontAwesomeIcon icon={faChevronRight} />
                            </span>
                        </Link>
                    </li>
                    <li>
                        <Link
                            onClick={() => this.onManageServiceClick("VenueUploader")}
                            className="side-bar-links__link"
                            to={{
                                pathname: "/matrixView",
                            }}
                        >
                            <span className="side-bar-links__icon">
                                <FontAwesomeIcon icon={faCloudUploadAlt} />
                            </span>
                            <span>VENUE HIERARCHY UPLOADER</span>
                            <span className="side-bar-links__number ml-auto mr-5"></span>
                            <span className="side-bar-links__chevron ">
                                <FontAwesomeIcon icon={faChevronRight} />
                            </span>
                        </Link>
                    </li>

                   {true && <li>
                        <Link
                            onClick={() => this.onManageServiceClick("clientUploadMappings")}
                            className="side-bar-links__link"
                            to={{
                                pathname: "/matrixView",
                                state: {
                                    navigateFrom: "clientUploadMappings",
                                },
                            }}
                        >
                            <span className="side-bar-links__icon">
                            <FontAwesomeIcon icon={faCloudUploadAlt} />
                            </span>
                            <span>CLIENT UPLOAD MAPPINGS</span>
                            <span className="side-bar-links__number ml-auto mr-5"></span>
                            <span className="side-bar-links__chevron ">
                                <FontAwesomeIcon icon={faChevronRight} />
                            </span>
                        </Link>
                    </li>}

                    <div className="mt-5 pt-3 sidebar__divider">
                        <h4 className="side-bar-links__heading">PROVIDER</h4>
                    </div>
                    <li>
                        <Link
                            onClick={() => this.onManageServiceClick(providerservice.venueProvider)}
                            className="side-bar-links__link"
                            to={{
                                pathname: "/matrixView",
                                state: {
                                    service: providerservice.venueProvider,
                                },
                            }}
                        >
                            <span className="side-bar-links__icon">
                                <FontAwesomeIcon icon={faSearch} />
                            </span>
                            <span>Venue Service Provider</span>
                            <span className="side-bar-links__number ml-auto mr-5"></span>
                            <span className="side-bar-links__chevron ">
                                <FontAwesomeIcon icon={faChevronRight} />
                            </span>
                        </Link>
                    </li>
                    <li>
                        <Link
                            onClick={() => this.onManageServiceClick("RepeatingService")}
                            className="side-bar-links__link"
                            to={{
                                pathname: "/matrixView",
                                state: {
                                    service: services.contract,
                                },
                            }}
                        >
                            <span className="side-bar-links__icon">
                                <FontAwesomeIcon icon={faRepeat} />
                            </span>
                            <span>Repeating Services</span>
                            <span className="side-bar-links__number ml-auto mr-5"></span>
                            <span className="side-bar-links__chevron ">
                                <FontAwesomeIcon icon={faChevronRight} />
                            </span>
                        </Link>
                    </li>
                    <li>
                        <Link
                            onClick={() => this.onManageServiceClick("ViewProviderContact")}
                            className="side-bar-links__link"
                            to={{
                                pathname: "/matrixView",
                                state: {
                                    service: services.contract,
                                },
                            }}
                        >
                            <span className="side-bar-links__icon">
                                <FontAwesomeIcon icon={faSearch} />
                            </span>
                            <span>VIEW CONTACTS</span>
                            <span className="side-bar-links__number ml-auto mr-5"></span>
                            <span className="side-bar-links__chevron ">
                                <FontAwesomeIcon icon={faChevronRight} />
                            </span>
                        </Link>
                    </li>
                    <li>
                        <Link
                            onClick={() => this.onManageServiceClick("viewProviders")}
                            className="side-bar-links__link"
                            to={{
                                pathname: "/matrixView",
                                state: {
                                    navigateFrom: "viewProviders",
                                },
                            }}
                        >
                            <span className="side-bar-links__icon">
                                <FontAwesomeIcon icon={faSearch} />
                            </span>
                            <span>View Providers</span>
                            <span className="side-bar-links__number ml-auto mr-5"></span>
                            <span className="side-bar-links__chevron ">
                                <FontAwesomeIcon icon={faChevronRight} />
                            </span>
                        </Link>
                    </li>
                    <li>
                        <Link
                            onClick={() => this.onManageServiceClick("providerUploadMappings")}
                            className="side-bar-links__link"
                            to={{
                                pathname: "/matrixView",
                                state: {
                                    navigateFrom: "providerUploadMappings",
                                },
                            }}
                        >
                            <span className="side-bar-links__icon">
                            <FontAwesomeIcon icon={faCloudUploadAlt} />
                            </span>
                            <span>PROVIDER UPLOAD MAPPINGS</span>
                            <span className="side-bar-links__number ml-auto mr-5"></span>
                            <span className="side-bar-links__chevron ">
                                <FontAwesomeIcon icon={faChevronRight} />
                            </span>
                        </Link>
                    </li>
                    <li>
                        <Link
                            onClick={() => this.onManageServiceClick(BULK_FLOW.BULK_INVITES)}
                            className="side-bar-links__link"
                            to={{
                                pathname: "/matrixView",
                                state: {
                                    navigateFrom: BULK_FLOW.BULK_INVITES,
                                },
                            }}
                        >
                            <span className="side-bar-links__icon">
                                <FontAwesomeIcon icon={faSitemap} />
                            </span>
                            <span>Send Bulk Invites</span>
                            <span className="side-bar-links__number ml-auto mr-5"></span>
                            <span className="side-bar-links__chevron ">
                                <FontAwesomeIcon icon={faChevronRight} />
                            </span>
                        </Link>
                    </li>
                    {false && <li>
                        <Link
                            onClick={() => this.onManageServiceClick(BULK_FLOW.BULK_CREATE_CONTACTS)}
                            className="side-bar-links__link"
                            to={{
                                pathname: "/matrixView",
                                state: {
                                    navigateFrom: BULK_FLOW.BULK_CREATE_CONTACTS,
                                },
                            }}
                        >
                            <span className="side-bar-links__icon">
                                <FontAwesomeIcon icon={faSitemap} />
                            </span>
                            <span>Create Bulk Contacts</span>
                            <span className="side-bar-links__number ml-auto mr-5"></span>
                            <span className="side-bar-links__chevron ">
                                <FontAwesomeIcon icon={faChevronRight} />
                            </span>
                        </Link>
                    </li>}
                    <div className="mt-5 pt-3 sidebar__divider">
                        <h4 className="side-bar-links__heading">ADMINISTRATION</h4>
                    </div>
                    <li>
                        <Link
                            onClick={() => this.onManageServiceClick("bulletins")}
                            className="side-bar-links__link"
                            to={{
                                pathname: "/matrixView",
                            }}
                        >
                            <span className="side-bar-links__icon fa-flip-horizontal">
                                <FontAwesomeIcon icon={faBullhorn} />
                            </span>
                            <span>BULLETINS</span>
                            <span className="side-bar-links__number ml-auto mr-5"></span>
                            <span className="side-bar-links__chevron ">
                                <FontAwesomeIcon icon={faChevronRight} />
                            </span>
                        </Link>
                    </li>
                    <li>
                        <Link
                            onClick={() => this.onManageServiceClick("FAQ")}
                            className="side-bar-links__link"
                            to={{
                                pathname: "/matrixView",
                            }}
                        >
                            <span className="side-bar-links__icon">
                            <FontAwesomeIcon icon={faQuestionCircle} />
                            </span>
                             <span>FAQ<span className="text-lowercase">s</span></span>
                            <span className="side-bar-links__number ml-auto mr-5"></span>
                            <span className="side-bar-links__chevron ">
                                <FontAwesomeIcon icon={faChevronRight} />
                            </span>
                        </Link>
                    </li>
                    <li>
                        <Link
                            // onClick={() => this.onManageServiceClick("serviceTypes")}
                            onClick={() => this.onManageServiceClick("")}
                            className="side-bar-links__link"
                            to={{
                                // pathname: "/matrixView",
                                pathname: "/",
                            }}
                        >
                            <span className="side-bar-links__icon">
                                <FontAwesomeIcon icon={faPeopleArrows} />
                            </span>
                            <span>SERVICE TYPES</span>
                            <span className="side-bar-links__number ml-auto mr-5"></span>
                            <span className="side-bar-links__chevron ">
                                <FontAwesomeIcon icon={faChevronRight} />
                            </span>
                        </Link>
                    </li>
                    <li>
                        <Link
                            onClick={() => this.onManageServiceClick("userView")}
                            className="side-bar-links__link"
                            to={{
                                pathname: "/matrixView",
                            }}
                        >
                            <span className="side-bar-links__icon fa-flip-horizontal">
                                <FontAwesomeIcon icon={faPeopleArrows} />
                            </span>
                            <span>User View</span>
                            <span className="side-bar-links__number ml-auto mr-5"></span>
                            <span className="side-bar-links__chevron ">
                                <FontAwesomeIcon icon={faChevronRight} />
                            </span>
                        </Link>
                    </li>
                    <li hidden={!UserService.isUserInGroup(RoleGroupNames.EventUKSeniorManager)}>
                        <Link
                            onClick={() => this.onManageServiceClick("ViewCompany")}
                            className="side-bar-links__link"
                            to={{
                                pathname: "/matrixView",
                            }}
                        >
                            <span className="side-bar-links__icon fa-flip-horizontal">
                                <FontAwesomeIcon icon={faPeopleArrows} />
                            </span>
                            <span>View Company</span>
                            <span className="side-bar-links__number ml-auto mr-5"></span>
                            <span className="side-bar-links__chevron ">
                                <FontAwesomeIcon icon={faChevronRight} />
                            </span>
                        </Link>
                    </li>
                    <li hidden={!UserService.isUserInGroup(RoleGroupNames.EventUKSeniorManager)}>
                        <Link
                            onClick={() => this.onManageServiceClick("managePayDates")}
                            className="side-bar-links__link"
                            to={{
                                pathname: "/matrixView",
                            }}
                        >
                            <span className="side-bar-links__icon fa-flip-horizontal">
                                <FontAwesomeIcon icon={faPiggyBank} />
                            </span>
                            <span>MANAGE PAY DATES</span>
                            <span className="side-bar-links__number ml-auto mr-5"></span>
                            <span className="side-bar-links__chevron ">
                                <FontAwesomeIcon icon={faChevronRight} />
                            </span>
                        </Link>
                    </li>
                    <li>
                        <Link
                            onClick={() => this.onManageServiceClick("ViewCompanyContact")}
                            className="side-bar-links__link"
                            to={{
                                pathname: "/matrixView",
                            }}
                        >
                            <span className="side-bar-links__icon fa-flip-horizontal">
                                <FontAwesomeIcon icon={faSearch} />
                            </span>
                            <span>VIEW TEAM</span>
                            <span className="side-bar-links__number ml-auto mr-5"></span>
                            <span className="side-bar-links__chevron ">
                                <FontAwesomeIcon icon={faChevronRight} />
                            </span>
                        </Link>
                    </li>
                </ul>
            </>
        );
    }
}
export default withTranslation()(ManageOurServicesPanel);
